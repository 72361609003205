import axios from 'axios';
import {API_VERSION} from '../../functions/users';

import {
  loginAPIResponse,
  logoutAPIResponse,
  forgetPasswordAPIResponse,
  resetPasswordAPIResponse
} from './action';

export function systemLoginThunk(data) {
  const userData = {
    username: data.username,
    password: data.password
  };

  return async function(dispatch) {
    try {
      console.log(userData);
      const arg = {
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/signin`,
        // url: `/api/signin`,
        data: userData,
        headers: { 'Content-Type': 'application/json' }
      };
      const response = await axios(arg);
      console.log('Login Response', response);

      if (response.status === 200) {
        dispatch(loginAPIResponse(response));
      }
    } catch (err) {
      if (err.response === undefined) {
        const networkFailRes = {
          status: 'failure',
          message: 'Network error please try again'
        };
        dispatch(loginAPIResponse(networkFailRes));
      } else {
        const failureRes = {
          message: err.response
        };
        dispatch(loginAPIResponse(failureRes));
      }
    }
  };
}

export function systemLogOutThunk() {
  return async function(dispatch) {
    try {
      const arg = {
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/signout`,
        // url: `/api/signout`,
        headers: { 'Content-Type': 'application/json' }
      };
      const response = await axios(arg);
      if (response.status === 200) {
        console.log('Log out Success', response);

        dispatch(logoutAPIResponse(response));
      }
    } catch (err) {
      if (err.response === undefined) {
        const networkFailRes = {
          status: 'failure',
          message: 'Network error please try again'
        };
        dispatch(logoutAPIResponse(networkFailRes));
      } else {
        const failureRes = {
          message: err.response
        };
        dispatch(logoutAPIResponse(failureRes));
      }
    }
  };
}

export function forgetPasswordThunk(data) {
  return async function(dispatch) {
    try {
      const arg = {
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URI}/api/forgotpassword`,
        // url: `/api/forgotpassword`,
        data: data,
        headers: { 'Content-Type': 'application/json' }
      };
      const response = await axios(arg);
      if (response.status === 200) {
        console.log('Password response', response);

        dispatch(forgetPasswordAPIResponse(response));
      }
    } catch (err) {
      if (err.response === undefined) {
        const networkFailRes = {
          status: 'failure',
          message: 'Network error please try again'
        };
        dispatch(forgetPasswordAPIResponse(networkFailRes));
      } else {
        const failureRes = {
          message: err.response
        };
        dispatch(forgetPasswordAPIResponse(failureRes));
      }
    }
  };
}

export function resetPasswordThunk(data, token) {
  return async function(dispatch) {
    try {
      const arg = {
        method: 'PUT',
        url: `${process.env.REACT_APP_SERVER_URI}/api/resetpassword/${token}`,
        // url: `/api/resetpassword/${token}`,
        data: data,
        headers: { 'Content-Type': 'application/json' }
      };
      const response = await axios(arg);
      if (response.status === 200) {
        console.log('resetPassword response', response);

        dispatch(resetPasswordAPIResponse(response));
      }
    } catch (err) {
      if (err.response === undefined) {
        const networkFailRes = {
          status: 'failure',
          message: 'Network error please try again'
        };
        dispatch(resetPasswordAPIResponse(networkFailRes));
      } else {
        const failureRes = {
          message: err.response
        };
        dispatch(resetPasswordAPIResponse(failureRes));
      }
    }
  };
}
