import React, { useRef, useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Table,
  Checkbox,
  Button,
  Space,
  message,
  Spin,
  Modal,
  Select,
  Typography,
  Input,
} from "antd";
import CardPreview from "./cardPreview";
import { SearchOutlined } from "@ant-design/icons";
import { downloadPdf } from "../../redux/import-export";
import { jsPDF } from "jspdf";
import { isArray } from "util";
import {
  rfidList,
  updateRfid,
  userGroup,
  searchUser,
  autheader,
} from "../../functions/users";

import { userList } from "../../functions/users";

const { Option } = Select;
const { Column } = Table;
const { Text } = Typography;

const CreationMembershipCard = () => {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const didMountUserListRef = useRef(false);

  // ******** Local State ********* //

  const [loading, setLoading] = useState(false);
  const [visibleCardPreviewModal, setVisibleCardPreviewModal] = useState(false);
  const [pdfUrls, setPDFUrls] = useState([]);
  const [checkedUsersData, setCheckedUsers] = useState([]);
  const [usersListData, setUsersListData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rfid, setRfidList] = useState([]);
  const [selectRfid, setSelectRfid] = useState("");
  const [sendRfid, setSendRfid] = useState("");
  const [selectEditValue, setSelectEditValue] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const [data, setData] = useState([]);
  const [backup, setBackup] = useState([]);
  const [paginationCount, setPaginationCount] = useState(1);
  const [selectedUserGroup, setSelectedUserGroup] = useState("");
  const [userGroupList, setUserGroupList] = useState([]);
  const [searchParam, setSearchParam] = useState("");

  const get = async (params) => {
    setLoading(true);
    try {
      const res = await userList();
      const d = res.data;
      setLoading(false);
      setPagination({ total: d.length });
      setData(d);
      setBackup(d);
    } catch (err) {
      return setLoading(false);
    }
  };

  // ******** Redux State ********* //

  const selectedUsersForPrint = useSelector(
    (state) => state.importExport.selectedUsersForPrint
  );

  const downloadPdfResponse = useSelector(
    (state) => state.importExport.downloadPdfResponse
  );

  const uploadUsersFileResponseState = useSelector(
    (state) => state.importExport.uploadUsersFileResponse
  );

  const userListData = useSelector((state) => state.importExport.userListData);

  // ******** Handlers ********* //

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onInputChange = (e) => {
    let inputString = e.target.value;
    setSearchParam(inputString);
  };

  const resetFunction = () => {
    setLoading(true);
    setSelectedRowKeys([]);
    // dispatch(userList());
  };

  // const showCardPreview = () => {
  //   setVisibleCardPreviewModal(true);
  // };

  const handleDownloadPdf = (docURL) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);

      window.open(`${process.env.REACT_APP_SERVER_URI}/${docURL}`);
    }, 6000);
  };

  const searchQueryFunction = () => {
    setLoading(true);
    let startDate = null;
    let endDate = null;
    searchUser(searchParam, startDate, endDate).then((res) => {
      setLoading(false);
      let newData = res.data;

      if (newData) {
        const list = newData;

        let dataAry = [];

        for (let i = 0; i < list.length; i++) {
          let newList = {};
          newList.cardNumber = list[i].rfid?.card_number;
          newList.username = `${list[i].firstname ? list[i].firstname : ""} ${
            list[i].surname ? list[i].surname : ""
          }`;
          newList.user_group = list[i].user_group;
          newList.key = list[i]["member_no"];
          newList["Mitglied-Nr"] = setIds(list[i]["member_no"]);
          newList.date = list[i].entry_date;
          dataAry.push(newList);
        }

        setUsersListData(dataAry);
      } else {
        message.error(newData.message);
      }
    });
  };

  // ******** Use Effects ********* //

  useEffect(() => {
    if (didMountRef.current) {
      setLoading(false);
      if (downloadPdfResponse) {
        if (downloadPdfResponse.status === 200) {
          const data = downloadPdfResponse.data;
          if (data.success) {
            console.log("success");
            handleDownloadPdf(data.url);
          } else {
            message.error(data.message);
          }
        } else {
          message.error(downloadPdfResponse.message);
        }
      }
    } else {
      didMountRef.current = true;
    }
  }, [downloadPdfResponse]);

  useEffect(() => {
    get();
  }, []);

  const setIds = (id) => {
    let numString = String(id);
    let number = numString.length;
    if (number === 7) {
      return numString;
    }
    let less = 7 - number;
    for (let i = 0; i < less; i++) {
      numString = "0" + numString;
    }
    return numString;
  };

  const handlePrintPdf = async (event) => {
    // setLoading(true);
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [10.8854, 6.8125],
    });
    var currentTimestamp = Date.now();
    for (let i = 0; i < selectedRowKeys.length; i++) {
      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/api/v1/getCardData/${selectedRowKeys[i]}`,
        { method: "GET", mode: "cors", headers: autheader() }
      );
      let data = await response.json();
      doc.setFont("Times");
      doc.setFontSize(70).setFont(undefined, "bold");

      // doc.text("2022", 8.4, 1.5);
      doc.text("2023", 8.4, 1.5);
      doc.setFontSize(34).setFont(undefined, "bold");
      let mem = data.member_no.toString();
      mem = mem.padStart(7, "0");

      doc
        .text(`${data.firstname} ${data.surname}`, 0.65, 6.3)
        .setFont(undefined, "bold");
      doc.setFontSize(30);

      doc.text(`${mem}`, 8.4, 6.3).setFont(undefined, "bold");
      console.log("here..............");

      // doc.text(`${data.firstname} ${data.surname}`, 0.65, 6.18);
      // doc.text(`${mem}`, 8.7, 6.18);

      if (i === selectedRowKeys.length - 1) {
        break;
      }
      doc.addPage([10.8854, 6.8125], "landscape");
    }

    doc.save(`${currentTimestamp}.pdf`);
    doc.output(`dataurlnewwindow`);
    console.log("here..............");
    // dispatch(downloadPdf(selectedRowKeys));
  };

  const loadRFIDListData = () => {
    rfidList().then((res) => {
      let rfidArr = res.data;
      let newRfid = [];
      for (let i = 0; i < rfidArr.length; i++) {
        if (
          rfidArr[i].card_number < "02801" ||
          rfidArr[i].card_number > "02900"
        ) {
          newRfid.push(rfidArr[i]);
        }
      }
      setRfidList(newRfid);
    });
  };

  const createUserGroupList = () => {
    userGroup().then((res) => {
      setUserGroupList(res.data);
    });
  };

  const editHandler = (e, y) => {
    setIsModalVisible(true);
    setSelectedUserGroup(y.user_group);
    setSelectRfid(y.rfid?.card_number);
    setSelectEditValue(y.member_no);

    let newUserList = data;

    let selectID = "";
    for (let i = 0; i < newUserList.length; i++) {
      if (setIds(newUserList[i]["member_no"]) === y.member_no) {
        selectID = newUserList[i].rfid?._id;
        break;
      }
    }

    setSendRfid(selectID);
  };

  const tableColumns = [
    {
      title: "Mitglied",
      dataIndex: null,
      key: "username",
      render: (r) => `${r.firstname} ${r.surname}`,
    },
    {
      title: "Mitgliedsnummer",
      dataIndex: "member_no",
      key: "Mitglied-Nr",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.member_no - b.member_no,
    },
    {
      title: "Eintrittsdatum",
      dataIndex: "entry_date",
      key: "entry_date",
    },
    { title: "Nutzergruppe", dataIndex: "user_group", key: "user_group" },
  ];

  const handleTableChange = (pg, filters, sorter) => {
    console.log(sorter);
    // let start = pg.current * pg.pageSize - pg.pageSize;
    // console.log(sorter)
    // let params = {
    //   limit: pg.pageSize,
    //   sortOrder : sorter.order == "descend" ? -1 : 1,
    //   start,
    //   ...filters,
    // };
    // get(params);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    updateRfid(selectEditValue, sendRfid, selectedUserGroup).then((res) => {
      setIsModalVisible(false);
      dispatch(userList());
      loadRFIDListData();
    });
    // setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onRfidChange = (value) => {
    setSelectRfid(value);
    setSendRfid(value);
  };

  const onGroupChange = (value) => {
    setSelectedUserGroup(value);
  };
  const onBlur = () => {
    console.log("blur");
  };

  const onFocus = () => {
    console.log("focus");
  };

  return (
    <Fragment>
      <CardPreview
        visible={visibleCardPreviewModal}
        setVisibleCardPreviewModal={setVisibleCardPreviewModal}
      />
      <Row>
        <Col span={24}>
          <h2 className="text-align-center">
            <Text strong>Erstellung Mitgliedsausweise</Text>
          </h2>
        </Col>
      </Row>
      <Row className="mb-16">
        <Col span={8}>
          <Input
            placeholder="Suchen..."
            allowClear
            onChange={(e) => {
              if (!e.target.value || !e.target.value.trim()) {
                setData(backup);
                setPagination({...pagination, total: backup.length});
              } else {
                if (e.target.value.length >= 2) {
                  let filtrr = backup;
                  const filteredData = filtrr.filter((entry) => {
                    let lcf = entry.firstname.toLowerCase();
                    return lcf.includes(e.target.value.toLowerCase());
                  });
                  setData(filteredData);
                  setPagination({...pagination, total: filteredData.length});
                }
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Space>
            SSC-Sparta 2022 : <Checkbox disabled />
            SSC-Sparta 2023 : <Checkbox defaultChecked disabled />
          </Space>
        </Col>
        <Col span={24}>
          <Spin spinning={loading}>
            <Table
              rowKey={(record) => record.member_no}
              rowSelection={rowSelection}
              columns={tableColumns}
              loading={loading}
              dataSource={data}
              pagination={pagination}
              onChange={handleTableChange}
              scroll={{ y: 240 }}
            />
          </Spin>
        </Col>
        <Row justify="end">
          <Col>
            <Space>
              <Button type="primary" onClick={handlePrintPdf}>
                Drucken
              </Button>
            </Space>
          </Col>
        </Row>
      </Row>
      <Modal
        title="Kartennummer und Benutzergruppe bearbeiten"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Speichern"
        cancelText="Abbrechen"
      >
        <Row>
          <Select
            value={selectRfid}
            // showSearch
            style={{ width: 200 }}
            placeholder="Wählen Sie Benutzer-RFID"
            optionFilterProp="children"
            onSelect={onRfidChange}
            onFocus={onFocus}
            onBlur={onBlur}
            // onSearch={onSearch}
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
          >
            {rfid.map((v) => (
              <Option key={v._id} value={v._id}>
                {v.card_number}
              </Option>
            ))}
          </Select>
        </Row>
        <br />
        <br />
        <Row>
          <Select
            value={selectedUserGroup}
            style={{ width: 200 }}
            placeholder="Wählen Sie Benutzergruppe"
            optionFilterProp="children"
            onSelect={onGroupChange}
            onFocus={onFocus}
            onBlur={onBlur}
          >
            {userGroupList.map((v) => (
              <Option key={v._id} value={v.group_name}>
                {v.group_name}
              </Option>
            ))}
          </Select>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default CreationMembershipCard;
