import React from "react";
import { Layout, Row, Col } from "antd";
const { Footer } = Layout;

const FooterComponent = () => {
  const copyrightText = "Schwimm Sport Club Sparta 1900 e.V.";

  return (
    <Footer style={{ textAlign: "center" }}>
      <a href="#">
        <img
          alt="mmgy-logo"
          className="footer-logo"
          src={process.env.PUBLIC_URL + "/assets/Logo.png"}
        />
      </a>
      {copyrightText}
    </Footer>
  );
};

export default FooterComponent;
