import LoginForm from "../components/loginForm";
import PageNotFound from "../components/pageNotFound";
import {
  importPageRoute,
  unauthorizedRoute,
  resetPasswordRoute,
  creationMembershipCardRoute,
  batchPrintRoute
} from "./routeRules";
import Unauthorized from "../sharedComponents/unauthorized";
import ResetPassword from "../components/resetPassword";
import ImportPage from "../components/importPage";
import CreationMembershipCard from "../components/CreationMembershipCard";
import BatchPrint from "../components/importPage/BatchPrint";
import Upload from "../components/uploads";

const routes = [
  {
    path: "/",
    exact: true, // this will match the exact url

    // authorization is use to check public private route
    // authorization = false then it is public route and anyone can access this path
    // authorization = true then it is private route and only logged in user can access this path
    authorization: false,
    // if websiteLayout = true then it will display header, footer , sidebar etc.
    // if websiteLayout =  false then it will not display header, footer , sidebar etc.
    websiteLayout: false,
    component: LoginForm,
  },
  {
    path: importPageRoute,
    permission: ["Administrator"],
    exact: true,
    authorization: true,
    websiteLayout: true,
    component: ImportPage,
  },
  {
    path: creationMembershipCardRoute,
    permission: ["Administrator"],
    exact: true,
    authorization: true,
    websiteLayout: true,
    component: CreationMembershipCard,
  },

  {
    path: batchPrintRoute,
    exact: true,
    authorization: true,
    websiteLayout: true,
    component: BatchPrint,
  },
  {
    path: unauthorizedRoute,
    exact: true,
    authorization: true,
    websiteLayout: false,
    component: Unauthorized,
  },
  {
    path: resetPasswordRoute,
    exact: true,
    authorization: false,
    websiteLayout: false,
    component: ResetPassword,
  },

  {
    path: "/uploads",
    exact: true,
    component: Upload,
  },
  {
    path: "*", // this will execute when above path doesn't matches
    authorization: false,
    websiteLayout: false,
    component: PageNotFound,
  },
];
export default routes;
