import * as types from './action-type';

const initialState = {
  uploadUsersFileResponse: null,
  selectedUsersForPrint: null,
  currentSidebarItemKey: null,
  exportFileResponse: null,
  historyDataResponse: null,
  downloadPdfResponse: null,
  userListData: null,
  listNotificationPreferenceResponseData: null,
  sendNotifications: null,
  filteredNotificationResponse: null,
};

const ImportExport = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.UPLOAD_USER_FILE_RESPONSE:
      return {
        ...state,
        uploadUsersFileResponse: payload
      };
    case types.SET_SELECTED_USERS_FOR_PRINT:
      return {
        ...state,
        selectedUsersForPrint: payload
      };
    case types.SET_SIDEBAR_ITEM_KEY:
      return {
        ...state,
        currentSidebarItemKey: payload
      };

    case types.EXPORT_FILE_RESPONSE:
      return {
        ...state,
        exportFileResponse: payload
      };

    case types.FILE_UPLOAD_HISTORY_RESPONSE:
      return {
        ...state,
        historyDataResponse: payload
      };

    case types.DOWNLOAD_PDF_RESPONSE:
      return {
        ...state,
        downloadPdfResponse: payload
      };

      case types.SEND_NOTIFICATIONS:
        return {
          ...state,
          sendNotifications: payload
        }
        
        case types.FILTERED_NOTIFICATION_RESPONSE:
          return {
            ...state,
            filteredNotificationResponse: payload
          }

    case types.USER_LIST_RESPONSE:
      return {
        ...state,
        userListData: payload
      };

    case types.LIST_NOTIFICATION_PREFERENCE_RESPONSE:
      return {
        ...state,
        listNotificationPreferenceResponseData: payload
      };

    default:
      return state;
  }
};

export default ImportExport;
