import React, { useEffect, useRef, useState, Fragment } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Menu, message, Spin } from "antd";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  SettingOutlined,
  FileAddOutlined,
  CreditCardOutlined,
  DatabaseOutlined,
  NotificationOutlined,
  LogoutOutlined,
  ArrowRightOutlined
} from "@ant-design/icons";
import { systemLogOutThunk } from "../../redux/authentication";
import { removeStorage } from "../../redux/authentication/action";
import {
  importPageRoute,
  creationMembershipCardRoute,
  pushNotificationRoute,
  membershipManagementRoute,
  reportsRoute,
  settingsRoute,
  userGroupAdminRoute,
  logsRoute,
  assignCardsRoute,
} from "../../routes/routeRules";
import { setSideBarItemKey } from "../../redux/import-export/action";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideBarMenu = () => {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const didMountRefSidebarKey = useRef(false);

  const history = useHistory();

  // **** Local State **** //

  const [collapsed, setCollapsed] = useState(false);
  const [currentSidebarItem, setCurrentSidebarItem] = useState("");

  // ******** Redux State ********* //

  const logoutAPIResponse = useSelector(
    (state) => state.authentication.logoutAPIResponse
  );

  const loginAPIResponse = useSelector(
    (state) => state.authentication.loginAPIResponse
  );

  const currentSidebarItemKey = useSelector(
    (state) => state.importExport.currentSidebarItemKey
  );

  // **** Handlesrs **** //

  const handleLogout = () => {
    dispatch(systemLogOutThunk());
  };

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const handleClick = (e) => {
    const currentKey = e.key;

    dispatch(setSideBarItemKey(currentKey));
  };

  // ******* useEffects ******** //

  useEffect(() => {
    if (didMountRef.current) {
      if (logoutAPIResponse) {
        if (logoutAPIResponse.status === 200) {
          const logoutAPIData = logoutAPIResponse.data;

          // setLoading(false);
          if (logoutAPIData.message === "Signout success") {
            dispatch(removeStorage());
            history.push("/");
            message.error("Signout success");
          } else {
            message.error("Unable to Signout. Please try again.");
          }
        } else {
          message.error(logoutAPIResponse.message);
        }
      }
    }
    didMountRef.current = true;
  }, [logoutAPIResponse]);

  useEffect(() => {
    setCurrentSidebarItem(currentSidebarItemKey);
  }, [currentSidebarItemKey]);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      width="350px"
      onCollapse={onCollapse}
    >
      <div style={{ textAlign: "center", padding: "8px" }}>
        <a href="#">
          <img
            alt="mmgy-logo"
            className="footer-logo"
            src={process.env.PUBLIC_URL + "/assets/Logo.png"}
          />
        </a>
      </div>
      <Menu
        theme="dark"
        selectedKeys={[currentSidebarItem]}
        mode="inline"
        onClick={handleClick}
      >
        {/* Import current Data */}
        <Menu.Item key="1" icon={<FileAddOutlined />}>
          <Link to={importPageRoute}>Import Mitgliederdaten</Link>
        </Menu.Item>
        {/* Creation of membership cards */}
        <Menu.Item key="2" icon={<UserOutlined />}>
          <Link to={creationMembershipCardRoute}>
            Erstellung Mitgliedsausweise
          </Link>
        </Menu.Item>
        {/* Management membership cards */}
       
    
        {/* Log out */}

        <Menu.Item key="7" onClick={handleLogout} icon={<LogoutOutlined />}>
          Ausloggen
        </Menu.Item>
       
      </Menu>
    </Sider>
    // </Spin>
  );
};

export default SideBarMenu;
