import {
  LOGIN_API_RESPONSE,
  LOGOUT_API_RESPONSE,
  REMOVE_STORAGE,
  FORGET_PASSWORD_API_RESPONSE,
  RESET_PASSWORD_API_RESPONSE
} from './action-type';

export const loginAPIResponse = payload => ({
  type: LOGIN_API_RESPONSE,
  payload
});

export const logoutAPIResponse = payload => {
  return {
    type: LOGOUT_API_RESPONSE,
    payload
  };
};

export const removeStorage = () => {
  return {
    type: REMOVE_STORAGE
  }
} 

export const forgetPasswordAPIResponse = payload => ({
  type: FORGET_PASSWORD_API_RESPONSE,
  payload
});

export const resetPasswordAPIResponse = payload => {
  return {
    type: RESET_PASSWORD_API_RESPONSE,
    payload
  };
};
